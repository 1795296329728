import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaClock, FaExternalLinkAlt, FaHistory, FaLightbulb, FaBookOpen } from 'react-icons/fa';
import DOMPurify from 'dompurify';
import './Recentcontest.css';

const RecentlyEndedContest = ({ contest, handleShowHint }) => {
	const [expandedProblem, setExpandedProblem] = useState(null);

	if (!contest) return null;

	const endedTime = new Date(contest.startTime);
	const now = new Date();
	const hoursAgo = Math.floor((now - endedTime) / (1000 * 60 * 60));

	const toggleExplanation = (problemIndex) => {
		setExpandedProblem(expandedProblem === problemIndex ? null : problemIndex);
	};

	const formatExplanation = (text) => {
		if (!text) return '';
		return DOMPurify.sanitize(text.replace(
			/(function|if|else|for|while|return)(\s+)([a-zA-Z_]\w*)/g,
			
		).replace(
			/(\/\/.+)/g,
			'<span class="comment">$1</span>'
		).replace(
			/(".*?")/g,
			'<span class="string">$1</span>'
		).replace(
			/\n/g,
			'<br>'
		));
	};

	return (
		<div className="recently-ended-contest-container">
			<div className="recently-ended-contest-header">
				<h1><FaHistory className="history-icon" /> Recently Ended</h1>
				<span className="contest-name">{contest.name}</span>
				<span className="contest-end-time">
					<FaClock /> {hoursAgo}h ago
				</span>
			</div>
			<div className="recently-ended-contest-problems">
				<div className="problem-table-header">
					<span>Problem</span>
					<span>Actions</span>
				</div>
				{contest.problems.map((problem, index) => (
					<React.Fragment key={index}>
						<div className="recently-ended-problem-item">
							<div className="problem-info">
								<span className="problem-letter">{String.fromCharCode(65 + index)}</span>
								<span className="problem-name">{problem.name}</span>
							</div>
							<div className="problem-actions">
								<button 
									className="hint-btn"
									onClick={() => handleShowHint(1, problem.hint)}
								>
									<FaLightbulb /> Hint 1
								</button>
								{problem.hint2 && (
									<button 
										className="hint-btn hint-2"
										onClick={() => handleShowHint(2, problem.hint2)}
									>
										<FaLightbulb /> Hint 2
									</button>
								)}
								<button 
									className="explained-solution-btn"
									onClick={() => toggleExplanation(index)}
								>
									<FaBookOpen /> {expandedProblem === index ? 'Hide' : 'Show'} Detailed Explanation With Tips and Tricks
								</button>
							</div>
						</div>
						{expandedProblem === index && (
							<div className="problem-explanation vscode-style">
								<div className="explanation-header">
									<span className="file-name">explanation.cpp</span>
									<div className="window-controls">
										<span className="control close"></span>
										<span className="control minimize"></span>
										<span className="control maximize"></span>
									</div>
								</div>
								<div className="explanation-content">
									{problem.explanation ? (
										<div dangerouslySetInnerHTML={{ __html: formatExplanation(problem.explanation) }} />
									) : (
										<p className="no-explanation">explanation will be added in 10-15 Minutes</p>
									)}
								</div>
							</div>
						)}
					</React.Fragment>
				))}
			</div>
			{contest.contest_link && (
				<a href={contest.contest_link} target="_blank" rel="noopener noreferrer" className="contest-link-btn">
					<FaExternalLinkAlt /> View Contest Page
				</a>
			)}
		</div>
	);
};

RecentlyEndedContest.propTypes = {
	contest: PropTypes.shape({
		name: PropTypes.string.isRequired,
		startTime: PropTypes.string.isRequired,
		problems: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string.isRequired,
			hint: PropTypes.string,
			hint2: PropTypes.string,
			explanation: PropTypes.string
		})).isRequired,
		contest_link: PropTypes.string
	}),
	handleShowHint: PropTypes.func.isRequired
};

export default RecentlyEndedContest;