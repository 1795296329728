import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import './AdminDashboard.css';
import PaymentSubmissions from './PaymentSubmissions';
import SolutionManager from './SolutionManager';

const AdminDashboard = () => {
    const [contests, setContests] = useState([]);
    const [newContest, setNewContest] = useState({ name: '', startTime: '', endTime: '', contestLink: '' });
    const [newProblem, setNewProblem] = useState({ contestId: '', name: '', hint: '', hint2: '', price: '', explanation: '' });
    const [editingContest, setEditingContest] = useState(null);
    const [editingProblem, setEditingProblem] = useState(null);
    const [subscribedUsers, setSubscribedUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [activeComponent, setActiveComponent] = useState('main');

    const [sendingEmails, setSendingEmails] = useState(false);

    const [newCompany, setNewCompany] = useState({
        name: '',
        date: '',
        solutions_available: false
    });

    const [newQuestion, setNewQuestion] = useState({
        company_id: '',
        title: '',
        google_doc_link: '',
        problem_statement: '' // Add this field
    });

    // Add companies state
    const [companies, setCompanies] = useState([]);

    // Add fetchCompanies function
    const fetchCompanies = async () => {
        try {
            const response = await axios.get('/company_oa.php');
            if (response.data.status === 'success') {
                setCompanies(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching companies:', error);
            setModalMessage('Error fetching companies. Please try again.');
            setShowModal(true);
        }
    };

    // Add these state variables
    const [editingCompany, setEditingCompany] = useState(null);
    const [editingQuestion, setEditingQuestion] = useState(null);

    // Add these handler functions
    const handleEditCompany = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/company_oa.php', {
                action: 'edit_company', // Add this line
                id: editingCompany.id,
                name: editingCompany.name,
                date: editingCompany.date,
                solutions_available: editingCompany.solutions_available
            });

            if (response.data.status === 'success') {
                setModalMessage('Company updated successfully!');
                setShowModal(true);
                setEditingCompany(null);
                fetchCompanies();
            }
        } catch (error) {
            console.error('Error updating company:', error);
            setModalMessage('Error updating company. Please try again.');
            setShowModal(true);
        }
    };

    const handleEditQuestion = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/company_oa.php', {
                action: 'edit_question', // Add this line
                id: editingQuestion.id,
                company_id: editingQuestion.company_id,
                title: editingQuestion.title,
                google_doc_link: editingQuestion.google_doc_link,
                problem_statement: editingQuestion.problem_statement
            });

            if (response.data.status === 'success') {
                setModalMessage('Question updated successfully!');
                setShowModal(true);
                setEditingQuestion(null);
                fetchCompanies();
            }
        } catch (error) {
            console.error('Error updating question:', error);
            setModalMessage('Error updating question. Please try again.');
            setShowModal(true);
        }
    };

    useEffect(() => {
        fetchContests();
        fetchSubscribedUsers();
        fetchCompanies(); // Add this line
    }, []);

    
    

    const fetchContests = async () => {
        try {
            const response = await axios.get('/contests.php');
            if (response.data.status === 'success') {
                console.log('Fetched contests:', response.data.data);
                setContests(response.data.data);
            } else {
                console.error('Error fetching contests:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching contests:', error);
        }
    };

    const fetchSubscribedUsers = async () => {
        try {
            const response = await axios.get('/subscribe.php');
            if (response.data.status === 'success') {
                console.log('Fetched subscribed users:', response.data.data);
                setSubscribedUsers(response.data.data);
            } else {
                console.error('Error fetching subscribed users:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching subscribed users:', error);
        }
    };

    const handleAddContest = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/contests.php', {
                action: 'add_contest',
                ...newContest
            });
            if (response.data.status === 'success') {
                setModalMessage('Contest and 5 problems added successfully!');
                setShowModal(true);
                setNewContest({ name: '', startTime: '', endTime: '', contestLink: '' });
                
                // Ensure that the new contest data has all required fields
                const newContestData = {
                    id: response.data.data.id,
                    contest_name: response.data.data.name,
                    start_time: response.data.data.startTime,
                    end_time: response.data.data.endTime,
                    contest_link: response.data.data.contestLink,
                    problems: [] // Initialize with an empty array of problems
                };
                
                // Update the contests state with the new contest data
                setContests(prevContests => [...prevContests, newContestData]);
                
                // Fetch the updated list of contests
                fetchContests();
            } else {
                setModalMessage('Error adding contest: ' + response.data.message);
                setShowModal(true);
            }
        } catch (error) {
            console.error('Error adding contest:', error);
            setModalMessage('Error adding contest and problems. Please try again.');
            setShowModal(true);
        }
    };

    const handleAddProblem = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/contests.php', {
                action: 'add_problem',
                ...newProblem,
                price: parseFloat(newProblem.price) // Ensure price is sent as a number
            });
            if (response.data.status === 'success') {
                fetchContests();
                setNewProblem({ contestId: '', name: '', hint: '', hint2: '', price: '', explanation: '' });
            }
        } catch (error) {
            console.error('Error adding problem:', error);
        }
    };

    const handleUpdateContest = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/contests.php', {
                action: 'update_contest',
                ...editingContest
            });
            if (response.data.status === 'success') {
                fetchContests();
                setEditingContest(null);
            }
        } catch (error) {
            console.error('Error updating contest:', error);
        }
    };

    const handleUpdateProblem = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/contests.php', {
                action: 'update_problem',
                ...editingProblem,
                price: parseFloat(editingProblem.price) // Ensure price is sent as a number
            });
            if (response.data.status === 'success') {
                fetchContests();
                setEditingProblem(null);
            }
        } catch (error) {
            console.error('Error updating problem:', error);
        }
    };

    const handleSendEmails = async () => {
        setSendingEmails(true);
        try {
            const response = await axios.get('/send.php');
            if (response.data.status === 'success') {
                setModalMessage(`Emails sent successfully! Sent: ${response.data.data.emailsSent}, Failed: ${response.data.data.emailsFailed}`);
            } else {
                setModalMessage('Error sending emails: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error sending emails:', error);
            setModalMessage('Error sending emails. Please try again.');
        } finally {
            setSendingEmails(false);
            setShowModal(true);
        }
    };

    const handleAddCompany = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/company_oa.php', {
                action: 'add_company',
                ...newCompany
            });
            
            if (response.data.status === 'success') {
                setModalMessage('Company added successfully!');
                setShowModal(true);
                setNewCompany({
                    name: '',
                    date: '',
                    solutions_available: false
                });
                fetchCompanies();
            }
        } catch (error) {
            console.error('Error adding company:', error);
            setModalMessage('Error adding company. Please try again.');
            setShowModal(true);
        }
    };

    const handleAddQuestion = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/company_oa.php', {
                action: 'add_question',
                company_id: newQuestion.company_id,
                title: newQuestion.title,
                google_doc_link: newQuestion.google_doc_link,
                problem_statement: newQuestion.problem_statement // Include problem statement
            });

            if (response.data.status === 'success') {
                // Reset form
                setNewQuestion({
                    company_id: '',
                    title: '',
                    google_doc_link: '',
                    problem_statement: ''
                });
                // Refresh data
                fetchCompanies();
            }
        } catch (error) {
            console.error('Error adding question:', error);
        }
    };

    return (
        <div className="admin-dashboard">
            <h1>Admin Dashboard</h1>
            <div className="dashboard-nav">
                <Button onClick={() => setActiveComponent('main')} className="mb-3 mr-2">Main Dashboard</Button>
                <Button onClick={() => setActiveComponent('payments')} className="mb-3 mr-2">Payment Submissions</Button>
                <Button onClick={() => setActiveComponent('solutions')} className="mb-3">Solution Manager</Button>
            </div>

            {activeComponent === 'main' && (
                <>
                    <section className="add-contest">
                        <h2>Add New Contest</h2>
                        <form onSubmit={handleAddContest}>
                            <input
                                type="text"
                                placeholder="Contest Name"
                                value={newContest.name}
                                onChange={(e) => setNewContest({ ...newContest, name: e.target.value })}
                                required
                            />
                            <input
                                type="datetime-local"
                                value={newContest.startTime}
                                onChange={(e) => setNewContest({ ...newContest, startTime: e.target.value })}
                                required
                            />
                            <input
                                type="datetime-local"
                                value={newContest.endTime}
                                onChange={(e) => setNewContest({ ...newContest, endTime: e.target.value })}
                                required
                            />
                            <input
                                type="url"
                                placeholder="Contest Link"
                                value={newContest.contestLink}
                                onChange={(e) => setNewContest({ ...newContest, contestLink: e.target.value })}
                                required
                            />
                            <button type="submit">Add Contest</button>
                        </form>
                    </section>

                    <section className="add-problem">
                        <h2>Add New Problem</h2>
                        <form onSubmit={handleAddProblem}>
                            <select
                                value={newProblem.contestId}
                                onChange={(e) => setNewProblem({ ...newProblem, contestId: e.target.value })}
                                required
                            >
                                <option value="">Select Contest</option>
                                {contests.map((contest) => (
                                    <option key={contest.id} value={contest.id}>{contest.contest_name}</option>
                                ))}
                            </select>
                            <input
                                type="text"
                                placeholder="Problem Name"
                                value={newProblem.name}
                                onChange={(e) => setNewProblem({ ...newProblem, name: e.target.value })}
                                required
                            />
                            <textarea
                                placeholder="Hint 1"
                                value={newProblem.hint}
                                onChange={(e) => setNewProblem({ ...newProblem, hint: e.target.value })}
                                required
                            ></textarea>
                            <textarea
                                placeholder="Hint 2"
                                value={newProblem.hint2}
                                onChange={(e) => setNewProblem({ ...newProblem, hint2: e.target.value })}
                                required
                            ></textarea>
                            <input
                                type="number"
                                step="0.01"
                                placeholder="Price"
                                value={newProblem.price}
                                onChange={(e) => setNewProblem({ ...newProblem, price: e.target.value })}
                                required
                            />
                            <textarea
                                placeholder="Explanation (optional)"
                                value={newProblem.explanation}
                                onChange={(e) => setNewProblem({ ...newProblem, explanation: e.target.value })}
                            ></textarea>
                            <button type="submit">Add Problem</button>
                        </form>
                    </section>

                    <section className="contest-list">
                        <h2>Existing Contests</h2>
                        {contests.filter(contest => contest && contest.id).map((contest) => (
                            <div key={contest.id} className="contest-item">
                                {editingContest && editingContest.id === contest.id ? (
                                    <form onSubmit={handleUpdateContest}>
                                        <input
                                            type="text"
                                            value={editingContest.contest_name}
                                            onChange={(e) => setEditingContest({ ...editingContest, contest_name: e.target.value })}
                                            required
                                        />
                                        <input
                                            type="datetime-local"
                                            value={editingContest.start_time}
                                            onChange={(e) => setEditingContest({ ...editingContest, start_time: e.target.value })}
                                            required
                                        />
                                        <input
                                            type="datetime-local"
                                            value={editingContest.end_time}
                                            onChange={(e) => setEditingContest({ ...editingContest, end_time: e.target.value })}
                                            required
                                        />
                                        <input
                                            type="url"
                                            value={editingContest.contest_link}
                                            onChange={(e) => setEditingContest({ ...editingContest, contest_link: e.target.value })}
                                            required
                                        />
                                        <button type="submit">Save</button>
                                        <button onClick={() => setEditingContest(null)}>Cancel</button>
                                    </form>
                                ) : (
                                    <>
                                        <h3>{contest.contest_name}</h3>
                                        <p>Start Time: {new Date(contest.start_time).toLocaleString()}</p>
                                        <p>End Time: {new Date(contest.end_time).toLocaleString()}</p>
                                        <p>Contest Link: <a href={contest.contest_link} target="_blank" rel="noopener noreferrer">{contest.contest_link}</a></p>
                                        <button onClick={() => setEditingContest(contest)}>Edit</button>
                                    </>
                                )}
                                <h4>Problems:</h4>
                                <ul>
                                    {contest.problems && contest.problems.length > 0 ? (
                                        contest.problems.map((problem) => (
                                            <li key={problem.id}>
                                                {editingProblem && editingProblem.id === problem.id ? (
                                                    <form onSubmit={handleUpdateProblem}>
                                                        <input
                                                            type="text"
                                                            value={editingProblem.name}
                                                            onChange={(e) => setEditingProblem({ ...editingProblem, name: e.target.value })}
                                                            required
                                                        />
                                                        <textarea
                                                            value={editingProblem.hint}
                                                            onChange={(e) => setEditingProblem({ ...editingProblem, hint: e.target.value })}
                                                            required
                                                        ></textarea>
                                                        <textarea
                                                            value={editingProblem.hint2}
                                                            onChange={(e) => setEditingProblem({ ...editingProblem, hint2: e.target.value })}
                                                            required
                                                        ></textarea>
                                                        <input
                                                            type="number"
                                                            step="0.01"
                                                            value={editingProblem.price}
                                                            onChange={(e) => setEditingProblem({ ...editingProblem, price: e.target.value })}
                                                            required
                                                        />
                                                        <textarea
                                                            value={editingProblem.explanation || ''}
                                                            onChange={(e) => setEditingProblem({ ...editingProblem, explanation: e.target.value })}
                                                            placeholder="Explanation (optional)"
                                                        ></textarea>
                                                        <button type="submit">Save</button>
                                                        <button onClick={() => setEditingProblem(null)}>Cancel</button>
                                                    </form>
                                                ) : (
                                                    <>
                                                        <p>Name: {problem.name}</p>
                                                        <p>Hint 1: {problem.hint}</p>
                                                        <p>Hint 2: {problem.hint2}</p>
                                                        <p>Price: ${problem.price}</p>
                                                        {problem.explanation && <p>Explanation: {problem.explanation}</p>}
                                                        <button onClick={() => setEditingProblem(problem)}>Edit</button>
                                                    </>
                                                )}
                                            </li>
                                        ))
                                    ) : (
                                        <li>No problems added yet</li>
                                    )}
                                </ul>
                            </div>
                        ))}
                    </section>

                    <section className="subscribed-users">
                        <h2>Subscribed Users</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>LeetCode</th>
                                    <th>CodeForces</th>
                                    <th>CodeChef</th>
                                    <th>AtCoder</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subscribedUsers.map((user) => (
                                    <tr key={user.id}>
                                        <td>{user.email}</td>
                                        <td>{user.leetcode ? '✓' : '✗'}</td>
                                        <td>{user.codeforces ? '✓' : '✗'}</td>
                                        <td>{user.codechef ? '✓' : '✗'}</td>
                                        <td>{user.atcoder ? '✓' : '✗'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>

                    <section className="add-company">
                        <h2>Add New Company</h2>
                        <form onSubmit={handleAddCompany}>
                            <input
                                type="text"
                                placeholder="Company Name"
                                value={newCompany.name}
                                onChange={(e) => setNewCompany({ ...newCompany, name: e.target.value })}
                                required
                            />
                            <input
                                type="date"
                                value={newCompany.date}
                                onChange={(e) => setNewCompany({ ...newCompany, date: e.target.value })}
                                required
                            />
                            <div className="checkbox-wrapper">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={newCompany.solutions_available}
                                        onChange={(e) => setNewCompany({ ...newCompany, solutions_available: e.target.checked })}
                                    />
                                    Solutions Available
                                </label>
                            </div>
                            <button type="submit">Add Company</button>
                        </form>
                    </section>

                    <section className="add-question">
                        <h2>Add New Question</h2>
                        <form onSubmit={handleAddQuestion}>
                            <select
                                value={newQuestion.company_id}
                                onChange={(e) => setNewQuestion({ ...newQuestion, company_id: e.target.value })}
                                required
                            >
                                <option value="">Select Company</option>
                                {companies.map((company) => (
                                    <option key={company.id} value={company.id}>{company.name}</option>
                                ))}
                            </select>
                            <input
                                type="text"
                                placeholder="Question Title"
                                value={newQuestion.title}
                                onChange={(e) => setNewQuestion({ ...newQuestion, title: e.target.value })}
                                required
                            />
                            <input
                                type="url"
                                placeholder="GitHub Images Folder or Google Doc Link"
                                value={newQuestion.google_doc_link}
                                onChange={(e) => setNewQuestion({ ...newQuestion, google_doc_link: e.target.value })}
                                required
                            />
                            <small className="form-text text-muted">
                                For OA images, paste the GitHub folder URL containing the images
                            </small>
                            {/* Add the problem statement textarea */}
                            <textarea
                                className="problem-statement-input"
                                placeholder="Problem Statement HTML"
                                value={newQuestion.problem_statement}
                                onChange={(e) => setNewQuestion({ 
                                    ...newQuestion, 
                                    problem_statement: e.target.value 
                                })}
                                rows={10}
                            ></textarea>
                            <button type="submit">Add Question</button>
                        </form>
                    </section>

                    {/* Display Companies and Questions */}
                    <section className="companies-list">
                        <h2>Manage Companies and Questions</h2>
                        {companies.map((company) => (
                            <div key={company.id} className="company-item">
                                {editingCompany && editingCompany.id === company.id ? (
                                    <form onSubmit={handleEditCompany}>
                                        <input
                                            type="text"
                                            value={editingCompany.name}
                                            onChange={(e) => setEditingCompany({
                                                ...editingCompany,
                                                name: e.target.value
                                            })}
                                            required
                                        />
                                        <input
                                            type="date"
                                            value={editingCompany.date}
                                            onChange={(e) => setEditingCompany({
                                                ...editingCompany,
                                                date: e.target.value
                                            })}
                                            required
                                        />
                                        <div className="checkbox-wrapper">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={editingCompany.solutions_available}
                                                    onChange={(e) => setEditingCompany({
                                                        ...editingCompany,
                                                        solutions_available: e.target.checked
                                                    })}
                                                />
                                                Solutions Available
                                            </label>
                                        </div>
                                        <div className="edit-actions">
                                            <button type="submit">Save</button>
                                            <button type="button" onClick={() => setEditingCompany(null)}>Cancel</button>
                                        </div>
                                    </form>
                                ) : (
                                    <>
                                        <h3>{company.name}</h3>
                                        <p>Date: {company.date}</p>
                                        <p>Solutions Available: {company.solutions_available ? 'Yes' : 'No'}</p>
                                        <div className="company-actions">
                                            <button onClick={() => setEditingCompany(company)}>Edit</button>
                                        </div>
                                    </>
                                )}

                                {/* Questions for this company */}
                                <div className="questions-list">
                                    <h4>Questions:</h4>
                                    {company.questions && company.questions.map((question) => (
                                        <div key={question.id} className="question-item">
                                            {editingQuestion && editingQuestion.id === question.id ? (
                                                <form onSubmit={handleEditQuestion}>
                                                    <input
                                                        type="text"
                                                        value={editingQuestion.title}
                                                        onChange={(e) => setEditingQuestion({
                                                            ...editingQuestion,
                                                            title: e.target.value
                                                        })}
                                                        required
                                                    />
                                                    <input
                                                        type="url"
                                                        value={editingQuestion.google_doc_link}
                                                        onChange={(e) => setEditingQuestion({
                                                            ...editingQuestion,
                                                            google_doc_link: e.target.value
                                                        })}
                                                        required
                                                    />
                                                    <textarea
                                                        className="problem-statement-input"
                                                        value={editingQuestion.problem_statement}
                                                        onChange={(e) => setEditingQuestion({
                                                            ...editingQuestion,
                                                            problem_statement: e.target.value
                                                        })}
                                                        rows={10}
                                                    ></textarea>
                                                    <div className="edit-actions">
                                                        <button type="submit">Save</button>
                                                        <button type="button" onClick={() => setEditingQuestion(null)}>Cancel</button>
                                                    </div>
                                                </form>
                                            ) : (
                                                <>
                                                    <h4>{question.title}</h4>
                                                    <div className="question-actions">
                                                        <button onClick={() => setEditingQuestion(question)}>Edit</button>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </section>
                </>
            )}

            {activeComponent === 'payments' && <PaymentSubmissions />}

            {activeComponent === 'solutions' && <SolutionManager />}

            <div className="dashboard-actions">
                <Button 
                    onClick={handleSendEmails} 
                    disabled={sendingEmails}
                    className="mb-3 mr-2"
                >
                    {sendingEmails ? 'Sending Emails...' : 'Send Contest Notifications'}
                </Button>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AdminDashboard;
