import React, { useState } from 'react';
import './Booking.css';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';

const Booking = () => {
  const [showBookingForm, setShowBookingForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    date: '',
    time: '',
    message: '',
  });
  const [responseMessage, setResponseMessage] = useState('');

  const handleInputChange = (e) => {
    setFormData({ 
      ...formData, 
      [e.target.name]: e.target.value 
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8888/codebusters/public/process_booking.php', formData);
      if (response.data.status === 'success') {
        setResponseMessage('Your booking has been successfully submitted!');
        setFormData({
          name: '',
          email: '',
          date: '',
          time: '',
          message: '',
        });
      } else {
        setResponseMessage(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error submitting booking:', error);
      setResponseMessage('There was an error submitting your booking. Please try again later.');
    }
  };

  return (
    <>
      <button className="sticky-contact-btn1" onClick={() => setShowBookingForm(true)} aria-label="Book OA Slot">
        Book Your OA Slot
      </button>

      {showBookingForm && (
        <div className="booking-popup-overlay">
          <div className="booking-popup-content glass-panel">
            <button className="close-modal" onClick={() => setShowBookingForm(false)}>
              <FaTimes />
            </button>
            <h3>📅 Book Your OA Slot</h3>
            {responseMessage && <p className="response-message">{responseMessage}</p>}
            <form onSubmit={handleSubmit} className="booking-form">
              <div className="input-group">
                <label htmlFor="name">Name</label>
                <input 
                  type="text" 
                  id="name" 
                  name="name" 
                  value={formData.name} 
                  onChange={handleInputChange} 
                  required 
                />
              </div>
              <div className="input-group">
                <label htmlFor="email">Email</label>
                <input 
                  type="email" 
                  id="email" 
                  name="email" 
                  value={formData.email} 
                  onChange={handleInputChange} 
                  required 
                />
              </div>
              <div className="input-group">
                <label htmlFor="date">Preferred Date</label>
                <input 
                  type="date" 
                  id="date" 
                  name="date" 
                  value={formData.date} 
                  onChange={handleInputChange} 
                  required 
                />
              </div>
              <div className="input-group">
                <label htmlFor="time">Preferred Time</label>
                <input 
                  type="time" 
                  id="time" 
                  name="time" 
                  value={formData.time} 
                  onChange={handleInputChange} 
                  required 
                />
              </div>
              <div className="input-group">
                <label htmlFor="message">Message</label>
                <textarea 
                  id="message" 
                  name="message" 
                  value={formData.message} 
                  onChange={handleInputChange} 
                  placeholder="Any specific requirements or questions?"
                  required 
                ></textarea>
              </div>
              <button type="submit" className="submit-btn">
                Submit Booking
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Booking;