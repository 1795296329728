import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes, FaBuilding, FaUserTie, FaHandshake, FaClock, FaInfoCircle, FaPaperPlane, FaEnvelope, FaLightbulb, FaHistory, FaUsers, FaUser } from 'react-icons/fa';
import './oahelp.css';

const OAHelp = () => {
  const [companies, setCompanies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedOA, setSelectedOA] = useState(null);
  
  const [companyName, setCompanyName] = useState('');
  const [oaDate, setOaDate] = useState('');
  const [description, setDescription] = useState('');
  
  const [registerName, setRegisterName] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  
  const [userEmail, setUserEmail] = useState('');

  const [registerCompany, setRegisterCompany] = useState('');
  const [registerOADate, setRegisterOADate] = useState('');
  const [sameOAEmail, setSameOAEmail] = useState('');
  const [sameOAFirstName, setSameOAFirstName] = useState('');
  const [showSameOAModal, setShowSameOAModal] = useState(false);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await axios.get('http://localhost:8888/codebusters/public/oa.php?action=get_companies');
      if (response.data.status === 'success') {
        setCompanies(response.data.data);
      } else {
        console.error('Error fetching companies:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const handleAddCompanySubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8888/codebusters/public/oa.php', {
        action: 'add_company',
        name: companyName,
        date: oaDate,
        description: description
      });
      if (response.data.status === 'success') {
        alert('Company added successfully');
        fetchCompanies();
        setShowModal(false);
        resetForm();
      } else {
        alert('Error adding company: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error adding company:', error);
      alert('Error adding company. Please try again.');
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8888/codebusters/public/oa.php', {
        action: 'register_for_oa_help',
        company: registerCompany,
        email: registerEmail,
        date: registerOADate,
        name: registerName
      });

      if (response.data.status === 'success') {
        alert(response.data.message);
        setShowRegisterModal(false);
        resetForm();
        fetchCompanies();
      } else {
        alert('Error registering for OA Help: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error registering for OA Help:', error);
      alert('Error registering for OA Help. Please try again.');
    }
  };

  const handleSameOASubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8888/codebusters/public/oa.php', {
        action: 'register_same_oa',
        email: sameOAEmail,
        firstName: sameOAFirstName,
        oaRoundId: selectedOA.id
      });

      if (response.data.status === 'success') {
        alert(response.data.message);
        setShowSameOAModal(false);
        resetForm();
        fetchCompanies(); // Refresh the companies list
      } else {
        alert('Error registering for Same OA: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error registering for Same OA:', error);
      alert('Error registering for Same OA. Please try again.');
    }
  };

  const handleSameOAClick = (company, oa) => {
    setSelectedCompany(company);
    setSelectedOA(oa);
    setShowSameOAModal(true);
  };

  const handleCheckOldOA = async (companyName) => {
    try {
      const response = await axios.post('http://localhost:8888/codebusters/public/oa.php', {
        action: 'get_old_oa_questions',
        companyName: companyName
      });
      if (response.data.status === 'success') {
        alert(`Old OA questions for ${companyName}:\n${JSON.stringify(response.data.data, null, 2)}`);
      } else {
        alert('No old OA questions found for this company');
      }
    } catch (error) {
      console.error('Error fetching old OA questions:', error);
      alert('Error fetching old OA questions. Please try again.');
    }
  };

  const handleEmailFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8888/codebusters/public/oa.php', {
        action: 'register_for_oa',
        name: userEmail.split('@')[0],
        email: userEmail,
        oaRoundId: selectedOA.id
      });
      if (response.data.status === 'success') {
        alert('Registered for OA successfully');
        setShowEmailForm(false);
        resetForm();
      } else {
        alert('Error registering for OA: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error registering for OA:', error);
      alert('Error registering for OA. Please try again.');
    }
  };

  const resetForm = () => {
    setCompanyName('');
    setOaDate('');
    setDescription('');
    setRegisterName('');
    setRegisterEmail('');
    setUserEmail('');
    setSelectedCompany(null);
    setSelectedOA(null);
    setRegisterCompany('');
    setRegisterOADate('');
    setSameOAEmail('');
    setSameOAFirstName('');
  };

  return (
    <div className="oa-help-container">
      <div className="button-group">
        <button className="open-modal-btn" onClick={() => setShowModal(true)}>
          <FaBuilding /> Add Company for OA
        </button>
        <button className="open-modal-btn" onClick={() => setShowRegisterModal(true)}>
          <FaUserTie /> Register for OA Help
        </button>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content glass-panel">
            <button className="close-modal" onClick={() => setShowModal(false)}>
              <FaTimes />
            </button>
            <h2>Add Company for Upcoming OA</h2>
            <form onSubmit={handleAddCompanySubmit} className="add-company-form">
              <div className="form-group">
                <label htmlFor="companyName"><FaBuilding /> Company Name</label>
                <input
                  type="text"
                  id="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="oaDate"><FaClock /> OA Date</label>
                <input
                  type="date"
                  id="oaDate"
                  value={oaDate}
                  onChange={(e) => setOaDate(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="description"><FaInfoCircle /> Description</label>
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                ></textarea>
              </div>
              <button type="submit" className="submit-btn">
                <FaPaperPlane /> Add Company
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="company-cards-section">
        <h2>All OA Rounds</h2>
        <div className="company-cards">
          {companies.map((company) => (
            <div key={company.id} className="company-card">
              <h3>{company.name}</h3>
              {company.oa_rounds.map((oa) => (
                <div key={oa.id} className="oa-round">
                  <div className="users-giving-oa">
                    <FaUsers /> {oa.usersGivingOA}
                  </div>
                  <p><FaClock /> {oa.date}</p>
                  <p className="description">{oa.description}</p>
                  <div className="card-buttons">
                    <button className="same-oa-btn" onClick={() => handleSameOAClick(company, oa)}>
                      <FaHandshake /> Same OA
                    </button>
                    <button className="check-old-oa-btn" onClick={() => handleCheckOldOA(company.name)}>
                      <FaHistory /> Old OA
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {showRegisterModal && (
        <div className="modal-overlay">
          <div className="modal-content glass-panel">
            <button className="close-modal" onClick={() => setShowRegisterModal(false)}>
              <FaTimes />
            </button>
            <h2>Register for OA Help</h2>
            <form onSubmit={handleRegisterSubmit} className="register-form">
              <div className="form-group">
                <label htmlFor="registerCompany"><FaBuilding /> Company Name</label>
                <input
                  type="text"
                  id="registerCompany"
                  value={registerCompany}
                  onChange={(e) => setRegisterCompany(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="registerEmail"><FaEnvelope /> Your Email</label>
                <input
                  type="email"
                  id="registerEmail"
                  value={registerEmail}
                  onChange={(e) => setRegisterEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="registerOADate"><FaClock /> OA Date</label>
                <input
                  type="date"
                  id="registerOADate"
                  value={registerOADate}
                  onChange={(e) => setRegisterOADate(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="registerName"><FaUserTie /> Your Name</label>
                <input
                  type="text"
                  id="registerName"
                  value={registerName}
                  onChange={(e) => setRegisterName(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="submit-btn">
                <FaPaperPlane /> Register
              </button>
            </form>
          </div>
        </div>
      )}

      {showSameOAModal && selectedCompany && selectedOA && (
        <div className="modal-overlay">
          <div className="modal-content glass-panel">
            <button className="close-modal" onClick={() => setShowSameOAModal(false)}>
              <FaTimes />
            </button>
            <h2>Register for Same OA: {selectedCompany.name}</h2>
            <p>OA Date: {selectedOA.date}</p>
            <form onSubmit={handleSameOASubmit} className="same-oa-form">
              <div className="form-group">
                <label htmlFor="sameOAFirstName"><FaUser /> First Name</label>
                <input
                  type="text"
                  id="sameOAFirstName"
                  value={sameOAFirstName}
                  onChange={(e) => setSameOAFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="sameOAEmail"><FaEnvelope /> Your Email</label>
                <input
                  type="email"
                  id="sameOAEmail"
                  value={sameOAEmail}
                  onChange={(e) => setSameOAEmail(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="submit-btn">
                <FaPaperPlane /> Register for Same OA
              </button>
            </form>
          </div>
        </div>
      )}

      {showEmailForm && selectedCompany && selectedOA && (
        <div className="modal-overlay">
          <div className="modal-content glass-panel">
            <button className="close-modal" onClick={() => setShowEmailForm(false)}>
              <FaTimes />
            </button>
            <h2>Register for {selectedCompany.name} OA</h2>
            <form onSubmit={handleEmailFormSubmit} className="email-form">
              <div className="form-group">
                <label htmlFor="userEmail"><FaEnvelope /> Your Email</label>
                <input
                  type="email"
                  id="userEmail"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="submit-btn">
                <FaPaperPlane /> Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default OAHelp;