import React, { useState, useEffect, lazy, Suspense } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './components/Navbar/Navbar';
import LandingPage from './components/LandingPage';
import ProblemSolutions from './components/ProblemSolutions';
import Booking from './components/Booking';
import OAHelp from './components/oahelp';
import './App.css';
import AdminDashboard from './components/Admin/AdminDashboard';
import CompanyOA from './components/CompanyOA';

// Lazy loaded components
const Snowfall = lazy(() => import('react-snowfall'));
const CPSheet = lazy(() => import('./components/CPSheet'));
const CodeTemplates = lazy(() => import('./components/CodeTemplates'));
const AddSolution = lazy(() => import('./components/AddSolution'));

function App() {
  const [showSnow, setShowSnow] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const getPageMetadata = () => {
    const path = window.location.hash.replace('#', '') || '/';
    
    const metadata = {
      '/': {
        title: 'CPHelper - Online Assessment Solutions & Programming Help',
        description: 'Get expert solutions for company online assessments, coding problems, and programming assignments. Your trusted partner for coding success.'
      },
      '/company-oa': {
        title: 'Company Online Assessment Solutions | CPHelper',
        description: 'Find solutions for Amazon, Google, Microsoft, and Meta online assessment questions. Expert guidance for coding interviews.'
      },
      '/oahelp': {
        title: 'OA Help - Get Expert Assistance | CPHelper',
        description: 'Professional help with online assessment questions and coding problems. 24/7 support for your coding challenges.'
      },
      '/cpsheet': {
        title: 'CP Sheet - Competitive Programming Practice | CPHelper',
        description: 'Curated list of competitive programming problems and solutions. Level up your coding skills.'
      },
      '/cptemplates': {
        title: 'CP Templates - Coding Templates Library | CPHelper',
        description: 'Ready-to-use competitive programming templates and algorithms. Optimize your coding workflow.'
      }
    };

    return metadata[path] || metadata['/'];
  };

  useEffect(() => {
    // Delay the snowfall animation
    const timer = setTimeout(() => {
      setShowSnow(true);
    }, 4000); // 3 seconds delay, adjust as needed

    // Load Tawk.to script
    var s1 = document.createElement("script");
    var s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/66fee7f2256fb1049b1c86fe/1i99rk478';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);

    // Cleanup function
    return () => {
      clearTimeout(timer);
      // Remove Tawk.to script when component unmounts
      if (s1 && s1.parentNode) {
        s1.parentNode.removeChild(s1);
      }
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="app">
      <Helmet>
        <title>{getPageMetadata().title}</title>
        <meta name="description" content={getPageMetadata().description} />
      </Helmet>

      {showSnow && (
        <Suspense fallback={<div>Loading...</div>}>
          <Snowfall
            color="rgba(255, 255, 255, 0.5)"
            snowflakeCount={30}
            radius={[1, 5]}
            speed={[0.5, 3]}
            wind={[-0.5, 2]}
          />
        </Suspense>
      )}

      <Navbar toggleMenu={toggleMenu} />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/problem-solutions/:problemName/:price" element={<ProblemSolutions />} />
          <Route path="/cptemplates" element={<CodeTemplates />} />
          <Route path="/cpsheet" element={<CPSheet />} />
          <Route path="/oahelp" element={<OAHelp />} />
          <Route path="/add-solution/:problemName" element={<AddSolution />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/company-oa" element={<CompanyOA />} />
        </Routes>
      </Suspense>
      
      {/* Add the sticky footer */}
      <div className="sticky-footer">
        <div className="footer-content">
          <span className="footer-text">For recently asked OA questions, visit </span>
          <a 
            href="https://oahelper.in" 
            target="_blank" 
            rel="noopener noreferrer"
            className="footer-link"
          >
            oahelper.in
          </a>
        </div>
      </div>

      {/* Add this div for Tawk.to widget */}
      <div id="tawkto-widget"></div>
    </div>
  );
}

export default App;
