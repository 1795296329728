import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import './PaymentSubmissions.css';

const PaymentSubmissions = () => {
    const [paymentSubmissions, setPaymentSubmissions] = useState([]);
    const [selectedSubmission, setSelectedSubmission] = useState(null);
    const [responseType, setResponseType] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [solutionExists, setSolutionExists] = useState(false);
    const [newSubmissionAlert, setNewSubmissionAlert] = useState(false);
    const [originalSolution, setOriginalSolution] = useState('');
    const [rewrittenSolution, setRewrittenSolution] = useState('');
    const lastSubmissionIdRef = useRef(null);
    const [showQRModal, setShowQRModal] = useState(false);
    const [currentQR, setCurrentQR] = useState('primary-qr.png');

    useEffect(() => {
        fetchPaymentSubmissions();
        const interval = setInterval(fetchPaymentSubmissions, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (newSubmissionAlert) {
            playNotificationSound();
            setTimeout(() => setNewSubmissionAlert(false), 5000);
        }
    }, [newSubmissionAlert]);

    const playNotificationSound = () => {
        try {
            const audio = new Audio('/notification-sound.mp3');
            audio.play().catch(error => {
                console.warn('Failed to play notification sound:', error);
            });
        } catch (error) {
            console.warn('Browser does not support Audio API:', error);
        }
    };

    const fetchPaymentSubmissions = async () => {
        try {
            const response = await axios.get('/admin_actions.php?action=get_payment_submissions');
            if (response.data.status === 'success') {
                const newSubmissions = response.data.data;
                if (newSubmissions.length > 0 && (lastSubmissionIdRef.current === null || newSubmissions[0].id > lastSubmissionIdRef.current)) {
                    setNewSubmissionAlert(true);
                    lastSubmissionIdRef.current = newSubmissions[0].id;
                }
                setPaymentSubmissions(newSubmissions);
            } else {
                console.error('Error fetching payment submissions:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching payment submissions:', error);
        }
    };

    const handleRespond = async (submission) => {
        setSelectedSubmission(submission);
        try {
            const response = await axios.get(`http://localhost:8888/codebusters/public/admin_actions.php?action=check_solution_exists&problem_name=${submission.problem_name}`);
            setSolutionExists(response.data.exists);
            if (response.data.exists) {
                const solutionResponse = await axios.get(`http://localhost:8888/codebusters/public/admin_actions.php?action=get_solution&problem_name=${submission.problem_name}`);
                if (solutionResponse.data.status === 'success') {
                    setOriginalSolution(solutionResponse.data.solution);
                } else {
                    console.error('Error fetching solution:', solutionResponse.data.message);
                }
            }
        } catch (error) {
            console.error('Error checking solution existence:', error);
        }
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        resetForm();
    };

    const resetForm = () => {
        setSelectedSubmission(null);
        setResponseType('');
        setResponseMessage('');
        setOriginalSolution('');
        setRewrittenSolution('');
        setSolutionExists(false);
    };

    const handleSendResponse = async () => {
        try {
            let messageToSend;
            let subject;
            
            if (responseType === 'sendSolution') {
                if (!originalSolution) {
                    alert('No solution available to send.');
                    return;
                }
                messageToSend = originalSolution;
                subject = `Solution for ${selectedSubmission.problem_name}`;
            } else if (responseType === 'custom') {
                messageToSend = responseMessage;
                subject = `Response for ${selectedSubmission.problem_name}`;
            } else {
                messageToSend = responseTemplates[responseType].body;
                subject = responseTemplates[responseType].subject;
            }

            const response = await axios.post('/admin_actions.php', {
                action: 'send_response',
                submissionId: selectedSubmission.id,
                responseType,
                message: messageToSend,
                subject: subject,
                problemName: selectedSubmission.problem_name
            });
            if (response.data.status === 'success') {
                alert(`Response sent successfully to ${selectedSubmission.email}`);
                fetchPaymentSubmissions();
                handleCloseModal();
            } else {
                alert('Error sending response: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error sending response:', error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
                alert(`Error sending response. Status: ${error.response.status}, Message: ${JSON.stringify(error.response.data)}`);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
                alert('Error sending response. No response received from server.');
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
                alert('Error sending response: ' + error.message);
            }
        }
    };

    const responseTemplates = {
        notReady: {
            subject: "Update on Your Solution Request",
            body: `
                <p>We apologize, but all the test cases for this problem have not been passed yet. Our team is working diligently to ensure the solution is accurate and comprehensive.</p>
                <p>We understand your eagerness to receive the solution, and we appreciate your patience. We will send the solution as soon as it's ready and thoroughly tested.</p>
                <p>In the meantime, if you have any questions or need further assistance, please don't hesitate to reach out to our support team.</p>
                <p>Thank you for your understanding and for choosing our service.</p>
            `
        },
        wrongScreenshot: {
            subject: "Action Required: Payment Screenshot Issue",
            body: `
                <p>Thank you for your recent submission. Upon review, we've noticed that the submitted screenshot is incorrect or doesn't meet our requirements.</p>
                <p>To proceed with your request, please submit a valid screenshot of your payment. Here are some guidelines to ensure your screenshot is accepted:</p>
                <ul>
                    <li>Ensure the full transaction details are visible</li>
                    <li>The screenshot should clearly show the date and time of the transaction</li>
                    <li>The amount paid should be clearly visible</li>
                    <li>Make sure the screenshot is clear and readable</li>
                </ul>
                <p>Once you've prepared a new screenshot that meets these criteria, please reply to this email with the updated image attached.</p>
                <p>We appreciate your cooperation and look forward to resolving this issue promptly.</p>
            `
        }
    };

    const handleQRClick = () => {
        setCurrentQR(currentQR === 'primary-qr.png' ? 'alternative-qr.png' : 'primary-qr.png');
    };

    const handleWrongSSToggle = async (submission) => {
        try {
            const newStatus = submission.status === 'wrongss' ? 'pending' : 'wrongss';
            console.log('Updating status to:', newStatus);

            const response = await axios.post('/admin_actions.php', {
                action: 'update_status',
                submissionId: submission.id,
                status: newStatus
            });

            console.log('Update response:', response.data);

            if (response.data.status === 'success') {
                // If marking as wrong SS, send notification email
                if (newStatus === 'wrongss') {
                    await axios.post('/admin_actions.php', {
                        action: 'send_response',
                        submissionId: submission.id,
                        responseType: 'wrongScreenshot',
                        message: responseTemplates.wrongScreenshot.body,
                        subject: 'Wrong Screenshot Notification',
                        problemName: submission.problem_name
                    });
                }
                
                // Immediately update the local state
                setPaymentSubmissions(prevSubmissions => 
                    prevSubmissions.map(sub => 
                        sub.id === submission.id 
                            ? {...sub, status: newStatus}
                            : sub
                    )
                );

                // Also fetch fresh data from server
                await fetchPaymentSubmissions();
            } else {
                alert('Error updating status: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error:', error.response?.data || error);
            alert('Error updating status. Check console for details.');
        }
    };

    return (
        <div className="payment-submissions">
            <h2>Payment Submissions</h2>
            {newSubmissionAlert && (
                <div className="alert alert-info" role="alert">
                    New submission received!
                </div>
            )}
            <Button onClick={() => setShowQRModal(true)} className="mb-3">
                View Payment QR Code
            </Button>
            <Table striped bordered hover className="submissions-table">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Problem Name</th>
                        <th>Screenshot</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {paymentSubmissions.map((submission) => (
                        <tr key={submission.id} className={submission.status === 'wrongss' ? 'wrong-ss-row' : ''}>
                            <td>{submission.email}</td>
                            <td>{submission.problem_name}</td>
                            <td>
                                <a href={submission.screenshot_url} target="_blank" rel="noopener noreferrer">
                                    View Screenshot
                                </a>
                            </td>
                            <td className={`status-cell ${submission.status}`}>
                                {submission.status}
                            </td>
                            <td className="action-cell">
                                <div className="d-flex align-items-center">
                                    <Button 
                                        onClick={() => handleRespond(submission)}
                                        className="me-2"
                                    >
                                        Respond
                                    </Button>
                                    <div className="wrong-ss-checkbox">
                                        <input
                                            type="checkbox"
                                            checked={submission.status === 'wrongss'}
                                            onChange={() => handleWrongSSToggle(submission)}
                                            className="wrong-ss-input"
                                            id={`wrong-ss-${submission.id}`}
                                        />
                                        <label 
                                            htmlFor={`wrong-ss-${submission.id}`}
                                            className="wrong-ss-label"
                                        >
                                            Wrong SS
                                        </label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showModal && (
                <Modal show={true} onHide={handleCloseModal} size="lg" className="top-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Respond to: {selectedSubmission?.problem_name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Response Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={responseType}
                                    onChange={(e) => setResponseType(e.target.value)}
                                >
                                    <option value="">Select response type</option>
                                    {solutionExists && <option value="sendSolution">Send Existing Solution</option>}
                                    <option value="notReady">Solution Not Ready</option>
                                    <option value="wrongScreenshot">Wrong Screenshot</option>
                                    <option value="custom">Custom Message</option>
                                </Form.Control>
                            </Form.Group>
                            {responseType === 'sendSolution' && (
                                <div className="solution-panel">
                                    <h4>Existing Solution</h4>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        value={originalSolution}
                                        readOnly
                                    />
                                    
                                </div>
                            )}
                            {responseType !== 'sendSolution' && (
                                <Form.Group>
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        value={responseType === 'custom' ? responseMessage : responseTemplates[responseType]?.body || ''}
                                        onChange={(e) => setResponseMessage(e.target.value)}
                                        readOnly={responseType !== 'custom'}
                                    />
                                </Form.Group>
                            )}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSendResponse}
                            disabled={!responseType || (responseType === 'sendSolution' && !originalSolution)}
                        >
                            Send Response
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* QR Code Modal */}
            <Modal show={showQRModal} onHide={() => setShowQRModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Payment QR Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <img 
                            src={currentQR} 
                            alt="Payment QR Code" 
                            className="qr-code-image" 
                            onClick={handleQRClick}
                        />
                        <p className="mt-3">
                            If this QR code is not accepting payment, click on the image to switch to an alternative QR code.
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default PaymentSubmissions;