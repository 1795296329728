import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { 
  FaChevronDown, 
  FaChevronUp, 
  FaBuilding, 
  FaSearch, 
  FaTimes, 
  FaLock, 
  FaEnvelope, 
  FaExternalLinkAlt,
  FaAmazon,
  FaGoogle,
  FaMicrosoft,
  FaApple,
  FaFacebook,
  FaLinkedin,
  FaUber,
  FaTwitter,
  FaStripe,
  FaSnapchat,
  FaHeadset,
  FaTelegram,
  FaGithub,
  FaAngleDown
} from 'react-icons/fa';
import { SiNetflix, SiTesla, SiOracle, SiAdobe } from 'react-icons/si';
import { GiBatMask } from 'react-icons/gi'; // for Batman logo
import './CompanyOA.css';

const CompanyOA = () => {
  const [companies, setCompanies] = useState([]);
  const [expandedCompany, setExpandedCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedSolutionButton, setExpandedSolutionButton] = useState(null);
  const [solutionEmail, setSolutionEmail] = useState('');
  const [expandedProblem, setExpandedProblem] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchCompanies = useCallback(async () => {
    try {
      setLoading(true);
      setError(null); // Reset error state before fetching
      
      const response = await axios.get('/company_oa.php', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (!response.data) {
        throw new Error('No data received from server');
      }

      if (response.data.status === 'success' && Array.isArray(response.data.data)) {
        setCompanies(response.data.data);
      } else {
        throw new Error(response.data.message || 'Invalid data format received');
      }
    } catch (err) {
      console.error('Error details:', err);
      setError(err.message || 'Failed to fetch companies');
      setCompanies([]); // Reset companies on error
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const toggleCompany = (companyId) => {
    setExpandedCompany(expandedCompany === companyId ? null : companyId);
  };

  // Update the filtering and sorting logic
  const filteredCompanies = companies
    .filter(company => 
      company.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      // Convert dates to timestamps and sort in descending order (newest first)
      return new Date(b.date) - new Date(a.date);
    });

  const handleSolutionClick = (questionId, e) => {
    e.stopPropagation();
    setExpandedSolutionButton(expandedSolutionButton === questionId ? null : questionId);
  };

  const handleSolutionSubmit = async (questionId, companyName, questionTitle, e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
        const response = await axios.post('/send_solution_mail.php', {
            email: solutionEmail,
            problemName: questionTitle,
            companyName: companyName
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        if (response.data.status === 'success') {
            alert('Payment instructions have been sent to your email!');
        } else {
            throw new Error(response.data.message || 'Failed to send email');
        }
    } catch (error) {
        console.error('Error:', error);
        alert('Failed to send email. Please try again later.');
    } finally {
        setIsSubmitting(false);
        setExpandedSolutionButton(null);
        setSolutionEmail('');
    }
  };

  const getCompanyIcon = (companyName) => {
    const name = companyName.toLowerCase();
    
    const iconMap = {
      'amazon': FaAmazon,
      'google': FaGoogle,
      'microsoft': FaMicrosoft,
      'apple': FaApple,
      'meta': FaFacebook,
      'facebook': FaFacebook,
      'linkedin': FaLinkedin,
      'uber': FaUber,
      'twitter': FaTwitter,
      'netflix': SiNetflix,
      'tesla': SiTesla,
      'oracle': SiOracle,
      'adobe': SiAdobe,
      'stripe': FaStripe,
      'snapchat': FaSnapchat
    };

    // Check if company name contains any of the keywords
    for (const [keyword, Icon] of Object.entries(iconMap)) {
      if (name.includes(keyword)) {
        return Icon;
      }
    }

    // Default icon if no match is found
    return FaBuilding;
  };

  const getTimeAgo = (date) => {
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays === 1) return '1 day ago';
    if (diffDays < 30) return `${diffDays} days ago`;
    if (diffDays < 365) {
      const months = Math.floor(diffDays / 30);
      return `${months} ${months === 1 ? 'month' : 'months'} ago`;
    }
    const years = Math.floor(diffDays / 365);
    return `${years} ${years === 1 ? 'year' : 'years'} ago`;
  };

  const handleProblemClick = (questionId, e) => {
    e.stopPropagation();
    setExpandedProblem(expandedProblem === questionId ? null : questionId);
  };

  if (loading) return (
    <div className="company-oa-wrapper">
      <div className="company-oa-container glass-panel">
        <div className="loading-state">Loading companies...</div>
      </div>
    </div>
  );

  if (error) return (
    <div className="company-oa-wrapper">
      <div className="company-oa-container glass-panel">
        <div className="error-state">
          <h3>Error loading companies</h3>
          <p>{error}</p>
          <button onClick={fetchCompanies} className="retry-button">
            Try Again
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="company-oa-wrapper">
      <div className="company-oa-container glass-panel">
        <div className="header-section">
          <div className="title-section">
            <h2 className="company-oa-title">Company Online Assessments</h2>
            <div className="cphelper-brand">
              <GiBatMask className="batman-logo" />
              <span className="cphelper-text">By CpHelper</span>
            </div>
          </div>
          <a 
            href="https://t.me/cphelperoa" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="get-help-button"
          >
            <FaTelegram className="telegram-icon" />
            <span className="button-text">Get OA Help</span>
            <span className="button-shine"></span>
          </a>
        </div>
        <div className="search-bar-container">
          <input
            type="text"
            placeholder="Search companies..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <FaSearch className="search-icon" />
        </div>
        <div className="company-table">
          <div className="company-table-header">
            <div className="column-title">Company</div>
            <div className="column-title">Date</div>
            <div className="column-title">Solutions</div>
            <div></div>
          </div>
          <div className="company-table-body">
            {filteredCompanies.map((company) => (
              <div key={company.id} className="company-row">
                <div className="company-data" onClick={() => toggleCompany(company.id)}>
                  <div className="company-name">
                    <div className="company-icon-wrapper">
                      {React.createElement(getCompanyIcon(company.name), {
                        className: "company-icon"
                      })}
                    </div>
                    <span className="company-name-text">{company.name}</span>
                  </div>
                  <div className="date-container">
                    {new Date(company.date).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric'
                    })}
                    <span className="date-ago">
                      {getTimeAgo(new Date(company.date))}
                    </span>
                  </div>
                  <div className="company-solutions">
                    <span className={`solution-badge ${company.solutions_available ? 'available' : 'unavailable'}`}>
                      {company.solutions_available ? 'Available' : 'Unavailable'}
                    </span>
                  </div>
                  <div className="expand-button">
                    {expandedCompany === company.id ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                </div>
                {expandedCompany === company.id && (
                  <div className="company-questions">
                    {company.questions.length > 0 ? (
                      company.questions.map((question, index) => (
                        <div key={question.id} className="question-item">
                          <div className="question-preview">
                            <div className="question-info">
                              <span className="question-number">{index + 1}.</span>
                              <h4 
                                className="question-title"
                                onClick={(e) => handleProblemClick(question.id, e)}
                              >
                                {question.title}
                                {!expandedProblem && 
                                    <span className="click-indicator">
                                        <FaAngleDown className="blink-arrow" />
                                        <span className="click-text">Click to view</span>
                                    </span>
                                }
                              </h4>
                              {/* Add GitHub images button */}
                              {question.google_doc_link && question.google_doc_link.includes('github.com') && (
                                <a 
                                  href={question.google_doc_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="github-images-btn"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <FaGithub /> View OA Images
                                </a>
                              )}
                              <div className="question-actions">
                                {question.google_doc_link && (
                                  <a 
                                    href={question.google_doc_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="view-problem-btn"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <FaExternalLinkAlt /> Original Problem Statement
                                  </a>
                                )}
                                <div className="solution-button-container">
                                  {expandedSolutionButton === question.id ? (
                                    <form 
                                      className="solution-email-form"
                                      onSubmit={(e) => handleSolutionSubmit(question.id, company.name, question.title, e)}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <div className="solution-form-content">
                                        <input
                                          type="email"
                                          placeholder="Enter your email"
                                          value={solutionEmail}
                                          onChange={(e) => setSolutionEmail(e.target.value)}
                                          required
                                          disabled={isSubmitting}
                                        />
                                        <button type="submit" disabled={isSubmitting}>
                                          {isSubmitting ? (
                                            <div className="simple-spinner">
                                              <div className="spinner-dot"></div>
                                            </div>
                                          ) : (
                                            <>
                                              <FaEnvelope /> Send
                                            </>
                                          )}
                                        </button>
                                      </div>
                                    </form>
                                  ) : (
                                    <button 
                                      className="get-solution-btn"
                                      onClick={(e) => handleSolutionClick(question.id, e)}
                                    >
                                      <FaLock /> Get Solution
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {expandedProblem === question.id && (
                            <div className="problem-statement">
                              <div 
                                className="problem-content"
                                dangerouslySetInnerHTML={{ __html: question.problem_statement }}
                              />
                              <div className="problem-footer">
                                <a 
                                  href={question.google_doc_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="view-problem-btn footer-btn"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <FaExternalLinkAlt /> Original Problem Statement
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <p>No questions available for this company.</p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyOA;
