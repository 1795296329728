import React from 'react';
import { FaClock, FaExternalLinkAlt, FaFire, FaRupeeSign, FaEye, FaLightbulb } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './LiveContest.css';

const LiveContest = ({ contest, contestStatus, handleShowHint, renderPrice }) => {
    if (!contest) return null;

    return (
        <div className="running-contest-container">
            <div className="running-contest-header">
                <h1><FaFire className="fire-icon" /> Live Contest</h1>
                <h1 className="contest-name">{contest.name}</h1>
                <span className="contest-timer">
                    <FaClock /> {contestStatus[contest.name]?.time}
                </span>
            </div>
            <div className="running-contest-problems">
                {contest.problems.map((problem, index) => (
                    <div key={index} className="running-problem-item">
                        <div className="problem-info">
                            <span className="problem-letter">{String.fromCharCode(65 + index)}</span>
                            <span className="problem-name">{problem.name}</span>
                            <span className="problem-price"><FaRupeeSign /> {renderPrice(problem.price)}</span>
                        </div>
                        <div className="problem-actions">
                            <button 
                                className="action-btn hint-btn"
                                onClick={() => handleShowHint(1, problem.hint)}
                            >
                                <FaLightbulb /> <span>Hint 1</span>
                            </button>
                            <button 
                                className="action-btn hint-btn"
                                onClick={() => handleShowHint(2, problem.hint2)}
                            >
                                <FaLightbulb /> <span>Hint 2</span>
                            </button>
                            <Link 
                                to={`/problem-solutions/${encodeURIComponent(problem.name)}/${encodeURIComponent(problem.price)}`} 
                                className="action-btn solution-btn"
                            >
                                <FaEye /> <span>Solution</span>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
            {contest.contest_link && (
                <a href={contest.contest_link} target="_blank" rel="noopener noreferrer" className="contest-link-btn">
                    <FaExternalLinkAlt /> Go to Contest
                </a>
            )}
        </div>
    );
};

export default LiveContest;