import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Table, Alert } from 'react-bootstrap';

const SolutionManager = () => {
    const [contests, setContests] = useState([]);
    const [selectedContest, setSelectedContest] = useState('');
    const [problems, setProblems] = useState([]);
    const [selectedProblem, setSelectedProblem] = useState('');
    const [solutionText, setSolutionText] = useState('');
    const [solutions, setSolutions] = useState([]);

    useEffect(() => {
        fetchContests();
        fetchSolutions();
    }, []);

    const fetchContests = async () => {
        try {
            const response = await axios.get('/admin_actions.php?action=get_contests');
            if (response.data.status === 'success') {
                setContests(response.data.data);
            } else {
                console.error('Error fetching contests:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching contests:', error);
        }
    };

    const fetchProblems = async (contestId) => {
        try {
            const response = await axios.get(`/admin_actions.php?action=get_problems&contest_id=${contestId}`);
            if (response.data.status === 'success') {
                setProblems(response.data.data);
            } else {
                console.error('Error fetching problems:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching problems:', error);
        }
    };

    const fetchSolutions = async () => {
        try {
            const response = await axios.get('/admin_actions.php?action=get_solutions');
            if (response.data.status === 'success') {
                setSolutions(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching solutions:', error);
        }
    };

    const handleContestChange = (e) => {
        const contestId = e.target.value;
        setSelectedContest(contestId);
        setSelectedProblem('');
        if (contestId) {
            fetchProblems(contestId);
        } else {
            setProblems([]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/add_solution.php', {
                action: 'add_solution',
                problem_id: selectedProblem,
                solution_text: solutionText
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data.status === 'success') {
                alert('Solution added successfully');
                setSolutionText('');
                setSelectedProblem('');
                fetchSolutions();
            } else {
                alert('Error adding solution: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error adding solution:', error);
            alert('Error adding solution. Please try again.');
        }
    };

    const handleSendSolution = async (solutionId) => {
        try {
            const response = await axios.post('/admin_actions.php', {
                action: 'send_solution_to_users',
                solution_id: solutionId
            });
            if (response.data.status === 'success') {
                alert('Solution sent to users successfully');
            }
        } catch (error) {
            console.error('Error sending solution:', error);
        }
    };

    return (
        <div className="solution-manager">
            <h2>Solution Manager</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Select Contest</Form.Label>
                    <Form.Control as="select" value={selectedContest} onChange={handleContestChange}>
                        <option value="">Choose a contest</option>
                        {contests.map((contest) => (
                            <option key={contest.id} value={contest.id}>{contest.contest_name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
                {selectedContest && (
                    <Form.Group>
                        <Form.Label>Select Problem</Form.Label>
                        <Form.Control as="select" value={selectedProblem} onChange={(e) => setSelectedProblem(e.target.value)}>
                            <option value="">Choose a problem</option>
                            {problems.map((problem) => (
                                <option key={problem.id} value={problem.id}>{problem.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                )}
                <Form.Group>
                    <Form.Label>Solution</Form.Label>
                    <Form.Control as="textarea" rows={5} value={solutionText} onChange={(e) => setSolutionText(e.target.value)} />
                </Form.Group>
                <Button type="submit">Add Solution</Button>
            </Form>

            <h3>Existing Solutions</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Contest</th>
                        <th>Problem</th>
                        <th>Solution</th>
                        <th>Created At</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {solutions.map((solution) => (
                        <tr key={solution.id}>
                            <td>{solution.contest_name}</td>
                            <td>{solution.problem_name}</td>
                            <td>{solution.solution_text.substring(0, 50)}...</td>
                            <td>{new Date(solution.created_at).toLocaleString()}</td>
                            <td>
                                <Button onClick={() => handleSendSolution(solution.id)}>Send to Users</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default SolutionManager;